<template>
  <main>
    <section
      class="z-20 fixed inset-0 outline-none focus:outline-none bg_faint"
    >
      <section
        class="
          absolute
          top-16
          lg:top-8
          bottom-8
          left-0
          right-0
          overflow-y-scroll
        "
      >
        <section class="py-3 lg:w-11/12 xl:w-2/3 w-full mx-auto z-30">
          <section
            class="
              px-2
              sm:px-4
              py-6
              bg-white
              mx-2
              sm:mx-8
              shadow
              rounded
              sm:p-10
            "
          >
            <div
              @click="$emit('close-edit-modal')"
              class="float-right cursor-pointer"
            >
              <close-icon :color="'text-red-700'"></close-icon>
            </div>
            <section class="bg-white pt-14 pb-12 px-4 sm:px-6 md:px-10 rounded">
              <div class="block sm:flex mb-6">
                <div
                  class="flex justify-center sm:mr-7 md:mr-12 lg:mr-14 xl:mr-20"
                >
                  <pet-icon
                    :width="'w-36 lg:w-40 xl:w-44'"
                    :height="'h-36 lg:h-40 xl:h-44'"
                    class="text-gray-300"
                  ></pet-icon>
                </div>
                <div
                  class="
                    text-center
                    sm:text-left sm:place-self-center sm:ml-8
                    mt-6
                    sm:mt-0
                  "
                >
                  <h1 class="text-2xl leading-10 font-semibold">
                    {{ pet_name }}
                  </h1>
                  <p class="text-base capitalize">
                    {{ pet_type }}, {{ pet_breed }}
                  </p>
                  <div class="mt-4">
                    <span
                      v-if="pet_isActive != false"
                      class="
                        bg-active_pill_color
                        px-3
                        py-1
                        rounded-3xl
                        text-sm
                        font-medium
                      "
                      >Active</span
                    >
                    <span
                      v-if="pet_isActive != true"
                      class="
                        bg-gray-300
                        px-3
                        py-1
                        rounded-3xl
                        text-sm
                        font-medium
                      "
                      >Not Active</span
                    >
                  </div>
                </div>
              </div>
              <more-pet-profile
                :view_pet_profile="pet_profile"
              ></more-pet-profile>
            </section>
          </section>
        </section>
      </section>
    </section>
  </main>
</template>

<script>
import PetIcon from '../../Icons/petIcon.vue'
import CloseIcon from '../../Icons/closeIcon.vue'
import MorePetProfile from '../../Pets/pet-profile/MorePetProfile.vue'

export default {
  components: {
    PetIcon,
    CloseIcon,
    MorePetProfile
  },
  props: {
    pet_profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      edit_pet_profile: false,
      pet_id: this.pet_profile.id,
      pet_name: this.pet_profile.pet_info.pet_name,
      pet_type: this.pet_profile.pet_info.pet_type,
      pet_breed: this.pet_profile.pet_info.pet_breed,
      pet_isActive: this.pet_profile.isActive
    }
  },
  methods: {
    editPetProfile() {
      this.edit_pet_profile = !this.edit_pet_profile
    }
  }
}
</script>

<style scoped>
.bg_faint {
  background-color: rgba(39, 40, 41, 0.384);
}
</style>
