<template>
  <main>
    <section
      class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-6"
    >
      <div
        class="
          shadow-md
          rounded
          bg-white
          py-8
          px-8
          flex flex-col
          justify-center
          text-center
          items-center
          relative
          card
        "
        v-for="(pet, index) in client_pet"
        :key="index"
      >
        <div class="my-4">
          <pet-icon
            :width="'w-20'"
            :height="'h-20'"
            class="text-gray-300"
          ></pet-icon>
        </div>
        <h1 class="text-lg font-medium leading-6">
          {{ pet.pet_info.pet_name }}
        </h1>
        <p class="text-gray-500">{{ pet.pet_info.pet_type }}</p>
        <div class="mt-2">
          <span
            v-if="pet.isActive != false"
            class="
              bg-active_pill_color
              px-3
              py-1
              rounded-3xl
              text-sm
              font-medium
            "
            >Active</span
          >
          <span
            v-if="pet.isActive != true"
            class="bg-gray-300 px-3 py-1 rounded-3xl text-sm font-medium"
            >Not Active</span
          >
        </div>
        <div class="absolute top-0 h-1/5 w-full card-top-bar">
          <div class="px-4 py-3 flex justify-between">
            <a
              href="javascript:;"
              @click="addReport(pet.id)"
              class="hover:text-primary"
            >
              <span v-if="pet.isActive != true">add report</span>
              <span v-if="pet.isActive != false">view report</span>
            </a>
            <div>
              <a href="javascript:;" @click="petOption(index)">
                <dotted-menu :color="'text-black'"></dotted-menu>
                <section>
                  <custom-option
                    :pet_id="pet.id"
                    v-if="pet_option == index"
                    @view-pet-details="viewClientPetProfile"
                  ></custom-option>
                </section>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <pet-profile
      @close-edit-modal="viewClientPetProfile"
      :pet_profile="pet_details"
      v-if="view_pet_details == true"
    ></pet-profile>

    <section>
      <loading-model v-if="loading"></loading-model>
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import PetIcon from '../../Icons/petIcon.vue'
import uniqid from 'uniqid'
import LoadingModel from '../../Utilities/loadingModel.vue'
import DottedMenu from '../../Icons/dottedMenu.vue'
import CustomOption from './CustomOption.vue'
import PetProfile from './PetProfile.vue'

export default {
  components: {
    PetIcon,
    LoadingModel,
    DottedMenu,
    CustomOption,
    PetProfile
  },
  props: {
    client_id: String
  },
  data() {
    return {
      pet_details: [],
      pet_option: null,
      view_pet_details: false
    }
  },
  created() {
    this.$store.dispatch('clients/clientPet', this.client_id)
  },
  computed: {
    ...mapState('clients', ['client_pet', 'loading']),
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    viewClientPetProfile() {
      this.view_pet_details = !this.view_pet_details
    },
    petOption(val) {
      this.pet_details = this.client_pet[val]
      if (val == this.pet_option) {
        this.pet_option = null
      } else {
        this.pet_option = val
      }
    },

    addReport(val) {
      var uniqueId = uniqid()
      var report = {
        id: uniqueId,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        finishedAt: false,
        hospital_id: this.user.hospital_id,
        pet_id: val,
        medical: {},
        laboratory: {},
        others: {}
      }
      this.$store.dispatch('reports/validator', report)
    }
  }
}
</script>

<style scoped>
.card:hover {
  @apply bg-gray-50;
  @apply opacity-90;
  @apply transition;
  @apply duration-300;
  @apply ease-in-out;
}
</style>
