<template>
  <main>
    <div>
      <router-link to="/reports" class="flex space-x-2 text-primary mb-6">
        <arrowback-icon></arrowback-icon>
        <span>back</span>
      </router-link>
    </div>
    <section class="lg:grid lg:grid-cols-3 lg:gap-x-12">
      <section class="col-span-2">
        <section class="bg-white py-8 px-6 overflow-x-hidden">
          <div class="md:grid md:grid-cols-4 items-center md:space-x-6">
            <div class="mt-8 md:mt-0 md:col-span-1">
              <div class="flex justify-center">
                <user-icon :height="'h-36'" :width="'w-36'"></user-icon>
              </div>
            </div>
            <div class="col-span-3">
              <div class="sm:pr-2">
                <div
                  class="
                    md:flex md:align-middle md:items-center
                    mb-1
                    text-center
                  "
                >
                  <h1 class="text-2xl font-semibold">
                    {{
                      client.client_details ? client.client_details.name : ''
                    }}
                  </h1>
                </div>
                <div class="flex justify-center md:block">
                  <small class="text-base">{{
                    client.client_details ? client.client_details.bus_name : ''
                  }}</small>
                </div>
                <div class="mt-5">
                  <div
                    class="
                      grid grid-cols-1
                      md:grid-cols-3
                      space-y-4
                      md:space-y-0
                    "
                  >
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Phone
                      </p>
                      <p>
                        {{
                          client.client_details
                            ? client.client_details.phone
                            : ''
                        }}
                      </p>
                    </div>
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Email
                      </p>
                      <p>
                        {{
                          client.client_details
                            ? client.client_details.email
                            : ''
                        }}
                      </p>
                    </div>
                    <div class="col-span-1">
                      <p class="text-gray-500 text-opacity-80 text-base">
                        Address
                      </p>
                      <p>
                        {{
                          client.client_details
                            ? client.client_details.address
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
    <client-pets-tab :client_id="id"></client-pets-tab>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import ArrowbackIcon from '../../Icons/arrowbackIcon.vue'
import UserIcon from '../../Icons/userIcon.vue'
import ClientPetsTab from './ClientPetsTab.vue'
export default {
  components: {
    ArrowbackIcon,
    UserIcon,
    ClientPetsTab
  },
  props: {
    id: String
  },
  created() {
    this.$store.dispatch('clients/read', this.id)
  },
  computed: mapState('clients', ['client', 'loading']),
  data() {
    return {}
  },
  methods: {}
}
</script>

<style></style>
