<template>
  <svg
    id="bold"
    enable-background="new 0 0 24 24"
    viewBox="0 0 24 24"
    class="fill-current inline"
    :class="[width, height, color]"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="3" />
    <circle cx="12" cy="3" r="3" />
    <circle cx="12" cy="21" r="3" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-5'
    },
    height: {
      type: String,
      default: 'h-5'
    },
    color: {
      type: String,
      default: 'text-white'
    }
  }
}
</script>
