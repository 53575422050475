<template>
  <div>
    <div class="relative">
      <div
        class="
          absolute
          bg-white
          border border-gray-50
          rounded
          shadow-md
          right-0
          text-xs
          w-32
          py-2
        "
      >
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="viewProfile"
        >
          View profile
        </div>
        <div
          class="py-1 px-4 hover:bg-gray-200 hover:shadow-inner cursor-pointer"
          @click="viewPetReportHistory"
        >
          report history
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    pet_id: String
  },
  data() {
    return {}
  },
  methods: {
    viewProfile() {
      this.$emit('view-pet-details')
    },
    viewPetReportHistory() {
      this.$router.push({
        name: 'ReportHistory',
        params: { pet_id: this.pet_id }
      })
    }
  }
}
</script>

<style></style>
